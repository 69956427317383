<div class="flex-grow-1"></div>
<div class="app-footer">
    <div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
        <a class="btn btn-primary text-white btn-rounded" href="https://info.zemyas.com" target="_blank"> En savoir plus</a>
        <span class="flex-grow-1"></span>
        <div class="d-flex align-items-center">
           <a  class="tx-ether  tx-bolder" style="font-family: 'Roboto'; text-align: center; ; vertical-align: middle; text-shadow: 0px 1px 0px #ffffff">
              <p class="tx-26 tx-ether"><i class="fal fa-globe-pointer bg-gray-100 tx-primary " style="padding:6px; border-radius:50%"></i>  ZEMYAS</p>
           </a>
           <div>
              <p class="m-0">&copy; 2023 OPEN2SW</p>
              <p class="m-0">All rights reserved</p>
           </div>
        </div>
    </div>
</div>